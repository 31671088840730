$header-height: 50px;
$menu-max-width: 25%;
$navbar-default-bg: #312312;
$light-orange: #ff8c00;
$color-orange: #e67300;
$btn-orange: #e67300;
$btn-orange-hover: #e67300;
//$btn-orange-hover: #f09400;
$navbar-default-color: $light-orange;
$white: #ffffff;
//$white: #fdfdfa;
$black: #000000;
$blue: #4486f4;
$pink: #e100c6;
$eastern-blue: #008091;
$turquoise: #ffbf00;
$turquoise-lighter: #40b9c7;
$turquoise-darker: #00b9ce;
$footer-background: #ecf0f1;
$footer-text: #009eb0;
$btn-blue: #008091;
$btn-blue-hover: #40b9c7;
$discount-background-red: #e73a2f;
$link-grey: #333;
$link-default: #0094ff;
$link-blue: #40b9c7;
$add-to-cart-blue: #4486f4;
$body-bg: #fff;
$body-color: #111;
$sk-green: #4caf50;
$sk-blue: #4486f4;
$theme-colors: (
    'primary': #216dad,
);
$theme-colors: (
    'accent': #669ecd,
);
$archie-gray: #f7f9fb;

$basket-sm-breakpoint: 784px;
$smaller-nav: small-navbar;
$small-animation-max-height: max-height 0.2s ease;
$small-animation-top: top 0.2s ease;
$small-animation-height: height 0.2s ease;

$headerHeight: 74px;
$headerMobileHeight: 165px;

$basketWidth: 420px;
