@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import url('https://rokshresources.blob.core.windows.net/fonts/public_sans/public_sans.css');
@import url('https://rokshresources.blob.core.windows.net/fonts/aldi/aldi-font.css');
/* *** Overall APP Styling can go here ***
   --------------------------------------------
   Note: This Component has ViewEncapsulation.None so the styles will bleed out
    @import url('https://fonts.googleapis.com/css?family=Open+Sans');
    @import url('https://fonts.googleapis.com/css?family=Open+Sans');
*/

* {
    font-family: 'Public Sans', sans-serif;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 9px;
}

::-webkit-scrollbar:horizontal {
    height: 9px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

html {
    scroll-behavior: auto !important;
}

body {
    background-color: #fff;
    overflow-x: hidden;

    a {
        color: $link-grey;
        cursor: pointer;
    }

    a:hover:not(.btn):not(.no-hover) {
        color: $link-blue;
        transition: 0.3s;
    }
}

.text-orange {
    color: $btn-orange;
}

.text-danger {
    color: #dc3545;
}

.text-success {
    color: $sk-green;
}

.text-green {
    color: $sk-green !important;
}

.text-blue {
    color: $sk-blue !important;
}

.text-grey {
    color: #737373 !important;
}

.panel {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
    height: 100%;
    flex: 1;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;

    .title {
        background-color: #86afd0;
        color: #ffffff;
        text-align: center;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    .body {
        display: flex;
    }
}

.font-weight-500 {
    font-weight: 500 !important;
}

.btn-warning {
    background: linear-gradient(180deg, #f38d27 0%, #e67300 100%), #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    color: #fff !important;
}

.btn-warning:hover {
    background: linear-gradient(180deg, #f38d27 0%, #e67300 100%), #ffffff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);
    border-color: #f38d27;
    transition: 0.3s;
}

.btn-outline-warning {
    border-color: $btn-orange !important;
    color: $btn-orange !important;
}

.btn-outline-warning:hover {
    background-color: $btn-orange !important;
    border-color: $btn-orange !important;
    color: #fff !important;
    transition: 0.3s;
}

.btn-primary {
    background-color: #838383 !important;
    border-color: #838383 !important;
}

.btn-outline-info {
    background-color: $white !important;
    border-color: #838383 !important;
    color: #838383 !important;
}

.btn-outline-info:hover {
    background-color: #838383 !important;
    border-color: #838383 !important;
    color: $white !important;
    transition: 0.3s;
}

.btn-success {
    background-color: $btn-blue !important;
    border-color: $btn-blue !important;
    color: #fff !important;
}

.btn-success:hover {
    background-color: $btn-blue-hover !important;
    border-color: $btn-blue-hover !important;
    transition: 0.3s;
}

.btn-danger {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-danger:hover {
    color: #ffffff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-big {
    padding: 0.5rem 0.7rem !important;
}

.btn-wide {
    padding-left: 1.35rem !important;
    padding-right: 1.35rem !important;
}

.font-size-14 {
    font-size: 0.875rem;
}

.letter-spacing-14 {
    letter-spacing: 1.4px;
}

.letter-spacing-12 {
    letter-spacing: 1.2px;
}

.letter-spacing-7 {
    letter-spacing: 0.72px;
}

.product-block-container .owl-carousel-container .owl-item {
    width: unset;
    display: inline-block;
    max-width: 200px !important;
}

.product-group-container .owl-item {
    width: unset;
    display: inline-block;
    max-width: 200px !important;
}

.earliest-timeslots-container .owl-item {
    width: unset;
    display: inline-block;
    max-width: 190px !important;
}

@media (max-width: 481px) {
    .product-block-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .product-block-container .owl-carousel-container .owl-item {
        width: unset;
        display: inline-block;
        max-width: 366px !important;
    }

    .product-group-container .owl-item {
        width: unset;
        display: inline-block;
        max-width: 366px !important;
    }
}

.owl-carousel .owl-item img.button-basket-icon {
    width: unset;
    display: inline-block;
}

.pointer {
    cursor: pointer;
}

.text-size-42 {
    font-size: 42px;
}

.text-size-34 {
    font-size: 34px;
}

.text-size-28 {
    font-size: 28px;
}

.text-size-25 {
    font-size: 25px;
}

.text-size-24 {
    font-size: 24px;
}

.text-size-22 {
    font-size: 24px;
}

.text-size-20 {
    font-size: 20px;
}

.text-size-18 {
    font-size: 18px;
}

.text-size-16 {
    font-size: 16px;
}

.text-size-14 {
    font-size: 14px;
}

.text-size-13 {
    font-size: 13px;
}

.text-size-12 {
    font-size: 12px;
}

.text-size-10 {
    font-size: 10px;
}

.font-weight-600 {
    font-weight: 600;
}

.btn-rounded {
    border-radius: 30px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.clearfix {
    clear: both !important;
}

.mat-menu-content .mat-menu-item:focus {
    outline: none;
}

@include media-breakpoint-down(md) {
    .panel {
        .body {
            flex-direction: column;
            padding: 0;
        }

        .title {
            font-size: 1.5rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .panel {
        .body {
            flex-direction: row;
            padding: 1.5rem;
        }
    }
}

.tooltip-break-line {
    white-space: pre-line !important;
}

.color-orange {
    color: $color-orange;
}

.color-eastern-blue {
    color: $eastern-blue;
}

.border-bottom-orange {
    border-bottom-color: $color-orange !important;
}

.border-orange {
    border-color: $color-orange !important;
}

.btn-turquoise {
    background-color: $turquoise;
    border-color: $turquoise;
    color: #fff;
}

.color-turquoise {
    color: $turquoise-lighter;
}

.turquoise-text {
    color: $turquoise;
}

.btn-outline-turquoise {
    border-color: $turquoise;
    color: $turquoise;
}

.btn-outline-turquoise:not(:disabled):not(.disabled):active,
.btn-turquoise:not(:disabled):not(.disabled).active,
.show > .btn-outline-turquoise.dropdown-toggle {
    color: #ffffff;
    background-color: $turquoise-lighter;
    border-color: $turquoise-lighter;
}

.btn-turquoise:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-turquoise.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 128, 145, 0.5);
}

.btn-outline-turquoise:hover,
.btn-turquoise:hover {
    color: #ffffff;
    background-color: $turquoise-lighter;
    border-color: $turquoise-lighter;
}

.notselectable {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}

$footerHeight: 175px;
$footerFloatingHeight: 96px;
$headerMobileHeight: 135px;

// Makes the footer at the bottom of the page
.router-container {
    min-height: calc(100vh - #{$footerHeight});
}

.floating-footer {
    min-height: calc(100vh - #{$footerFloatingHeight});
}

@media (max-width: 767px) {
    .router-container {
        min-height: calc(100vh - #{$headerMobileHeight});
    }
}

// Solve the problem of conflict of mat-input hint and error
.mat-form-field-underline,
.mat-form-field-subscript-wrapper,
.mat-form-field-ripple {
    position: initial !important;
    display: block;
}

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

.wrapper {
    margin-bottom: 20px;
}

/*****************************************************
	Custom styling example bellow.
*****************************************************/

.iti {
    display: block !important;
    margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: #c7cace;
    margin-top: -1px;
}

.iti .iti__country-list {
    box-shadow: none;
    font-size: 14px;
    margin-left: 0;
    width: 244px;
    max-height: 170px;
}

.iti__flag-container.open + input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.iti .search-container input {
    font-size: 14px;
    border-color: #c7cace;
    border-radius: 0;
    padding: 5px 10px;
}

.iti .search-container input:focus {
    outline: none;
}

@media screen and (max-width: 479px) {
    .iti .iti__country-list {
        width: 88.3vw;
    }
}

ngx-intl-tel-input input {
    height: 44px;
    margin-bottom: 20px;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #c7cace;
    border-radius: 4px;
    font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
    border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
    outline: none !important;
    border-color: #3498db;
    box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
    background-color: #e5eaf1;
}

.success-snackbar {
    background: green !important;
    color: white;
}

.error-snackbar {
    background: red !important;
    color: white;
}

.warning-snackbar {
    background: orange !important;
    color: white;
}

.roksh-btn-primary {
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    border: 0;
    cursor: pointer;
}

.roksh-btn-primary:disabled,
.roksh-btn-primary[disabled] {
    opacity: 0.4;
}

.gradient {
    position: relative;
    background-image: linear-gradient(180deg, #f99531 0%, #e67300 100%);
    z-index: 1;
    overflow: hidden;
}

.gradient::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, #f99531 0%, #d76700 100%);
    transition: opacity 0.2s linear;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
}

.gradient:hover::before {
    z-index: -1;
    opacity: 1;
}

.badge-primary {
    color: #ffffff;
    background-color: #4486f4;
}
a.badge-primary:hover,
a.badge-primary:focus {
    color: #ffffff;
    background-color: #1467f1;
}
a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 134, 244, 0.5);
}
.badge-secondary {
    color: #ffffff;
    background-color: #6c757d;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #ffffff;
    background-color: #545b62;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
    color: #ffffff;
    background-color: #28a745;
}
a.badge-success:hover,
a.badge-success:focus {
    color: #ffffff;
    background-color: #1e7e34;
}
a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info {
    color: #ffffff;
    background-color: #17a2b8;
}
a.badge-info:hover,
a.badge-info:focus {
    color: #ffffff;
    background-color: #117a8b;
}
a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
    color: #212529;
    background-color: #ffc107;
}
a.badge-warning:hover,
a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00;
}
a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
    color: #ffffff;
    background-color: #dc3545;
}
a.badge-danger:hover,
a.badge-danger:focus {
    color: #ffffff;
    background-color: #bd2130;
}
a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}
a.badge-light:hover,
a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5;
}
a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
    color: #ffffff;
    background-color: #343a40;
}
a.badge-dark:hover,
a.badge-dark:focus {
    color: #ffffff;
    background-color: #1d2124;
}
a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.badge-accent {
    color: #ffffff;
    background-color: #669ecd;
}
a.badge-accent:hover,
a.badge-accent:focus {
    color: #ffffff;
    background-color: #4086c0;
}
a.badge-accent:focus,
a.badge-accent.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 158, 205, 0.5);
}

.alert-total-price {
    height: 50px;
    background: #f6be42;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: white;
    width: 92%;
}

.percentage {
    color: #212529;
    background-color: #ffbcbc;
    font-size: 12px;
}

.checkout-panel-header {
    padding-right: 0px !important;
}

.tel-input {
    padding-bottom: 12px;
    padding-top: 12px;
}

.tel-input-invalid {
    border: 1px solid #ef3f2e !important;
    box-shadow: none !important;
}

.tel-input-label {
    font-size: 12px;
    font-weight: 200;
}

.ngx-tel-input-label {
    top: -18px !important;
    z-index: 100;
}

.custom-snack-bar {
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    .custom-time-slot-modal {
        mat-dialog-container {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            overflow-x: hidden;
        }
    }
    .custom-shared-list {
        mat-dialog-container {
            width: 100vw !important;
            padding: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .custom-shared-list {
        mat-dialog-container {
            width: 50vw !important;
        }
    }
}
